<script setup lang="ts">
import { LeftArrowIcon, SearchIcon } from '@glow/shared/components/icons';
import { useRoutes } from '@glow/shared/lib/routes';
import { useBodyScrolling } from '@glow/shared/store/bodyScrolling';
import AppLayoutMobileSearchScreen from './AppLayoutMobileSearchScreen.vue';

const { getUrl } = useRoutes();
const router = useRouter();
const isSearchScreenVisible = ref(false);
const { enableBodyScrolling, disableBodyScrolling } = useBodyScrolling();

function goBack() {
  if (window.history.length > 1) router.go(-1);
  else navigateTo(getUrl('clientHome'));
}
function showSearchScreen() {
  isSearchScreenVisible.value = true;
  disableBodyScrolling();
}

function hideSearchScreen() {
  isSearchScreenVisible.value = false;
  enableBodyScrolling();
}
</script>

<template>
  <div class="header px-4 py-6">
    <AppButton
      variant="link"
      icon-start-color="white"
      :icon-start="LeftArrowIcon"
      @click="goBack()"
    />
    <div class="text-white font-bold">
      <slot name="title" />
    </div>
    <slot name="end">
      <AppButton
        variant="link"
        icon-start-color="white"
        :icon-start="SearchIcon"
        @click="showSearchScreen()"
      />
    </slot>
    <ClientOnly>
      <Teleport to="body">
        <AppLayoutMobileSearchScreen
          v-if="isSearchScreenVisible"
          class="search-screen"
          @close="hideSearchScreen()"
        />
      </Teleport>
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
.header {
  background: var(--app-gradient-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
